<template>
<div class="c-app">

    <ContentSidebar />
    <CWrapper>
        <ContentHeader />
        <div class="c-body">
            <main class="c-main">
                <CContainer fluid>
                    <!-- <notifications position="bottom right" /> -->
                    <transition name="fade" mode="out-in">
                        <router-view :key="$route.path">

                        </router-view>
                    </transition>
                </CContainer>
            </main>
        </div>
        <ContentFooter />
    </CWrapper>
</div>
</template>

<script>
import ContentSidebar from './ContentSidebar'
import ContentHeader from './ContentHeader'
import ContentFooter from '../ContentFooter'

export default {
    name: 'ContentLayout',
    components: {
        ContentSidebar,
        ContentHeader,
        ContentFooter
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style><style>
/* Button blue */
.btn-blue {
    background: #2d2d52;
    border-color: #2d2d52;
    color: #ffffff;
}

.btn-blue:hover {
    color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}

.btn-blue:focus,
.btn-blue.focus {
    color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}
.btn-blue:active,
.btn-blue.active {
   color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}

.btn-blue.disabled,
.btn-blue:disabled {
    background-color: #232335;
    border-color: #232335;
}


.font-color-white {
    color: #ffffff;
}

.font-color-s-white {
    color: #ebebeb;
}

.without-href,
.without-href:hover {
    color: #ffffff;
}

.page-item.active .page-link {
    background: #636f83 !important;
    border-color: #636f83 !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #303063 !important;
}

@media (hover: hover),
not all {

    .c-sidebar .c-sidebar-nav-link:hover,
    .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: #fff;
        background: #303063 !important;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #303063 !important;
}

.nav-link {
    color: #303063;
}

.nav-link:hover {
    color: #434388;
}

/* ------------- */
.lg-numero {
    font-size: 30px;
    font-weight: bold;

}

.btn-custom {
    color: #636F83;
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.btn-custom:hover {
    color: #fff;
    background-color: #636F83;
    border-color: #636F83;
}

.btn-custom:focus,
.btn-custom.focus {
    box-shadow: 0 0 0 0px #636F83;
}

.btn-custom.disabled,
.btn-custom:disabled {
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.btn-custom:active,
.btn-custom.active,
.show>.btn-custom.dropdown-toggle {
    background-color: #636F83;
    background-image: none;
    border-color: #636F83;
}

.icon-custom .ico {
    color: #cfcfcf;
}

.icon-custom:hover .ico {
    color: #636F83;
}
/*----------v-select-------*/

.vs__selected {
    color: #768192 !important;
}

.vs__search,
.vs__search:focus {
    color: #a0a1a2 !important;
    margin: 6px 0 2px !important;
}

.vs__dropdown-option {
    color: #626b77 !important;
}

.vs__clear {
    visibility: hidden;
}

.style-invalid-select .vs__dropdown-toggle {
    border-color: #e55353 !important;
}

.style-valid-select .vs__dropdown-toggle {
    border-color: #2eb85c !important;
}

.vs__dropdown-toggle {
    border-color: #d8dbe0 !important;
    border: 1px solid;
    background: #ffffff !important;

}
.vs__dropdown-option--highlight {
    background: #626b77 !important;
    color: #ffff !important;
}

.disabled-white {
    background-color: #ffffff !important;
    color: rgb(118, 129, 146) !important;
      }



/*----------
Hidden Modal footer----*/

.modal-footer {
    display: none;
}
/*----------hr vertical----*/
.vr {
    border-right: 1px solid #CCC;
}
/*----table details---*/
.b-table-details {
    background: #f0f8ff;
}

</style>
